<template>
  <div>
    <div class="title flexB" style="display: block;">
      <h1>{{ $t("top-select-vod-manager-contents") }}</h1>
    </div>
    <div class="contents vodCont">
      <div class="box one filter" style="display: flex;flex-direction: row;flex-wrap: wrap;">
        <div class="flex">
          <p class="bold">{{ $t("search-keyword-title") }}</p>
          <select v-model="keywordType">
            <option value="all">{{ $t("search-keyword-all") }}</option>
            <!-- <option value="">VOD ID</option> -->
            <option value="title">{{ $t("vod-data-title") }}</option>
            <option value="eventViewId">{{ $t("event-data-site-num") }}</option>
            <option value="eventName">{{ $t("event-data-site-name") }}</option>
            <option value="registerUserName">{{ $t("live-data-channel-operator") }}</option>
          </select>
          <input
              type="text"
              v-model="keyword"
              @keydown.enter="search()"
          />
        </div>
        <div class="flex">
          <p class="bold" style="margin-left: 10px;">{{ $t("search-regdate-title") }}</p>
          <Datepicker
              v-model="startYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
          <span>~</span>
          <Datepicker
              v-model="endYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
        <div class="flexB" style="max-width: 700px; margin-bottom: 0;">
          <div class="flex" style="margin-bottom: 0">
            <p class="bold" style="margin-left: 10px;">{{ $t("data-operation-device-type") }}</p>
            <label
            ><input
                type="radio"
                name="device"
                checked
                v-model="deviceType"
                value=""
            />{{ $t("data-operation-device-type-all") }}</label
            >
            <label
            ><input
                type="radio"
                name="device"
                v-model="deviceType"
                value="Drone"
            />{{ $t("data-operation-device-type-drone") }}</label
            >
            <label
            ><input
                type="radio"
                name="device"
                v-model="deviceType"
                value="Mobile"
            />{{ $t("data-operation-device-type-phone") }}</label
            >
          </div>
          <div class="flex" style="margin-bottom: 0">
            <p class="bold">{{ $t("vod-data-status") }}</p>
            <label
            ><input
                type="checkBox"
                ref="viewDeletedContents"
                name="deleteView"
                value=""
                style="margin-right: 0px;"
                @change="handleDeletedViewList"
                :checked="viewDeletedContents"
            /><span style="font-size: 1.2rem;">{{ $t("vod-data-status-delete-view-chk") }}</span></label>
          </div>
        </div>
        <div class="buttonWrap" style="display: flex; margin-top: 15px;">
            <button class="point medium" @click="search()" style="width: 120px; margin-left: 0px;">
              {{ $t("btn-searching") }}
            </button>
            <button class="medium margin6" @click="reset" style="width: 120px;">
              {{ $t("btn-reset") }}
            </button>
          </div>
      </div>
      <div class="box one">
        <div class="flexB" style="flex-flow: row; margin-bottom: 8px;">
          <h2 class="bold" style="margin-bottom: 0;line-height: 28px;">
            {{ $t("data-total") }} <span class="blue">{{ total }}</span
          >{{ $t("data-case-ea") }}
          </h2>
          <button v-if="!viewDeletedContents" class="redLineBtn" @click="onVodDeleteList" style="width: 106px; height: 28px;" >
            {{ $t("btn-delete") }}
          </button>

          <div class="buttonWrap" v-else style="margin-top: 0px;">
            <button class="point" @click="onVodRestoreList" style="width: 106px; height: 28px; margin-right: 5px;">
              복구하기
            </button>
            <button class="blkBtn" @click="reset" style="width: 106px; height: 28px;">
              {{ $t("btn-delete") }}
            </button>
          </div>
        </div>
        <table style="margin-bottom: 5px;">
          <tr>
            <th>{{ $t("user-data-dept-select")}}</th>
            <th>{{ $t("vod-data-thum") }}</th><!--썸네일-->
            <th>{{ $t("vod-data-title") }}</th><!--제목-->
            <th>{{ $t("event-data-site-num") }}</th><!--현장번호-->
            <th>{{ $t("event-data-site-name") }}</th><!--현장명-->
            <th>{{ $t("data-operation-device-type") }}</th><!--기기구분-->
            <th>{{ $t("live-data-channel-operator") }}</th><!--촬영자-->
            <th>{{ $t("live-data-channel-operator-dept") }}</th><!--촬영자 소속-->
            <th>{{ $t("vod-data-registered-date") }}</th><!--등록일-->
            <!-- <th>기기 SD 파일</th> -->
          </tr>
          <colgroup>
            <col style="width: 5%;"/>
            <col style="width: 10%;"/>
            <col style="width: 15%;"/>
            <col style="width: 10%;"/>
            <col style="width: 15%;"/>
            <col style="width: 10%;"/>
            <col style="width: 10%;"/>
            <col style="width: 10%;"/>
            <col style="width: 10%;"/>
          </colgroup>
          <tr v-if="total == 0">
            <td colspan="9" style="text-align: center;">
              {{ $t("search-not-found") }}
            </td>
          </tr>
          <tr v-for="(data, i) in vodList" :key="i">
            <td>
              <label
              ><input
                  type="checkbox"
                  name="delSelect"
                  v-model="selectIds"
                  :value="data.contentsId"
              /></label>
            </td>
            <td>
              <router-link :to="`contentsDetail?contentsId=${data.contentsId}`">
                <div class="thumBox" style="position: relative;">
                  <img style="width: 80px;" :src="baseUrl + data.thumbnailUri" />
                  <div class="duration">
                    <span class="duration">{{ changeDuration(data.duration) }}</span>
                  </div>
                </div>
              </router-link>
            </td>
            <td>
              {{ data.title }}
            </td>
            <td>{{ data.eventViewId != null ? data.eventViewId : data.eventId  }}</td>
            <td>
              {{ data.eventTitle }}
<!--              <span v-if="data.eventTitle.toString().length > 12" style="font-size: 1.2rem;">
                {{ data.eventTitle.toString().substring(0, 12) + "..." }}
              </span>
              <span v-else style="font-size: 1.2rem;">
                {{ data.eventTitle }}
              </span>-->
            </td>
            <td>
              {{$t(getDeviceTypeName(data.deviceType)) }}
            </td>
            <td>{{ data.registerUserName }}</td>
            <td>{{ data.registerDepartmentName }}</td>
            <td>
              {{ moment(data.registerDate).format("YYYY.MM.DD HH:mm:ss") }}
            </td>
          </tr>
        </table>
        <div class="pagination">
          <el-pagination
              small
              layout="prev, pager, next"
              :total="total"
              :size="size"
              :currentPage = "currentPage + 1"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { baseUrl } from "@/config/env";
import { fetchVodList, deleteVodList, fetchRestoreListVod, fetchVodThumbnail } from "@/api/contents";
import { getDeviceTypeName } from "@/api/channel";

export default {
  components: { Datepicker },
  name: "ContentsList",
  data() {
    return {
      baseUrl: baseUrl,
      ko: ko,
      moment: moment,
      startYmd: null,
      endYmd: null,
      vodList: [],
      currentPage: 0,
      size: 10,
      keywordType: "all",
      keyword: "",
      total: 0,
      statuses: "",
      thumbnail: "",
      deviceType: "",
      selectIds : [],
      viewDeletedContents: false,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 7, sub: 0 });
    this.preset();
  },
  mounted() {
    this.callFunctionsWhenMounted();
  },
  methods: {
    changeDuration(seconds) {
      if(seconds == null) {
        return "00:00";
      }
      var min = parseInt((seconds%3600)/60) < 10 ? '0'+ parseInt((seconds%3600)/60) : parseInt((seconds%3600)/60);
      var sec = seconds % 60 < 10 ? '0'+seconds % 60 : seconds % 60;
      return min+":" + sec;
    },
    getDeviceTypeName(data){
      return getDeviceTypeName(data);
    },
    preset(){
      var localStorageData = localStorage.getItem("contents_searchData");
      if(localStorageData != undefined) {
        var searchData = JSON.parse(localStorageData);
        this.keyword = searchData.keyword;
        this.keywordType = searchData.keywordType;
        this.userTypes = searchData.userTypes;
        this.statuses = searchData.statuses;
        this.currentPage = searchData.pageNumber;
        this.startYmd =  searchData["dateRange.from"];
        this.endYmd =  searchData["dateRange.to"];
      }
    },
    persist(params){
      localStorage.setItem("contents_searchData", JSON.stringify(params)); // 로컬 스토리지에 저장
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getVodList();
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    search() {
      if (this.startYmd != null) {
        if (this.startYmd > this.endYmd || !this.endYmd || !this.startYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
      }
      this.currentPage = 0;
      this.getVodList();
    },

    async getVodList() {
      let params = {
        pageNumber: this.currentPage,
        pageSize: this.size,
        keywordType: this.keywordType,
        keyword: this.keyword,
        statuses: this.statuses,
        deviceType: this.deviceType,
      };
      params["registeredDateRange.from"] = null;
      params["registeredDateRange.to"] = null;

      if (this.startYmd && this.endYmd) {
        let from = this.startYmd ? moment(this.startYmd).format("YYYY-MM-DD") : null;
        let to = this.endYmd ? moment(this.endYmd).format("YYYY-MM-DD") : null;
        params["registeredDateRange.from"] = from;
        params["registeredDateRange.to"] = to;
      }

      this.persist(params);
      await fetchVodList(params).then((res) => {
        this.total = res.data.data.total;
        this.vodList = res.data.data.content;

        for (let i in this.vodList) {
          this.vodList[i].thumbnail = this.getThumbnail(
              this.vodList[i].contentsId
          );
        }
      });
    },
    async getThumbnail(contentId) {
      let thumb = "";
      await fetchVodThumbnail(contentId).then((res) => {
        thumb = res.data;
      });
      return thumb;
    },
    handleRefresh() {
      this.reset();
      this.callFunctionsWhenMounted();
    },
    handleDeletedViewList(e){
      if(e.target.checked){
        this.currentPage = 0;
        this.statuses = "Remove";
      }else{
        this.statuses  = "";
      }
      this.getVodList();
      this.getThumbnail();
      this.viewDeletedContents = e.target.checked;
      localStorage.setItem("viewDeletedContents", e.target.checked);
    },
    reset(){
      this.currentPage = 0;
      this.keywordType = "all";
      this.keyword = "";
      this.statuses = "";
      this.deviceType = "";
      this.startYmd = null;
      this.endYmd = null;
    },
    callFunctionsWhenMounted(){
      let viewDeletedContents = localStorage.getItem("viewDeletedContents");
      if(viewDeletedContents === "true"){
        this.viewDeletedContents = true;
      }else{
        this.viewDeletedContents = false;
        localStorage.setItem("viewDeletedContents", "false");
      }
      if(this.statuses === ""){
        this.viewDeletedContents = false;
        localStorage.setItem("viewDeletedContents", "false");
      }
      this.getVodList();
      this.getThumbnail();
    },
    onVodDeleteList(){
      if (!confirm(this.$t("alert-message-delete-contents-message")) ) {
        return;
      }
      let params = {
        ids : this.selectIds.join(",")
      }
      deleteVodList(params).then((res) => {
        if (res.data.result == 0) {
          this.selectIds = [];
          this.getVodList();
        }
      });
    },
    onVodRestoreList(){
      if (!confirm(this.$t("alert-message-restore-contents-message"))  ) {
        return;
      }

      let params = {
        ids : this.selectIds
      }
      fetchRestoreListVod(params).then((res) => {
        if (res.data.result == 0) {
          this.selectIds = [];
          this.getVodList();
        }
      });
    },
  },
};
</script>

<style>
.title{margin-top: 0;}
div.duration {
  position: absolute;
  width: 36px !important;
  height: 18px !important;
  right: 0;
  bottom: 0;
  padding: 1px 4px 2px 5px;
  background-color: rgba(30, 30, 30, 0.5);
}
span.duration {
  font-family: Noto Sans KR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
</style>
